import CryptoJS from "crypto-js";

export const encrypt = (plainText: string, Key: string, IV: string): string  => {
    try{
        const key = CryptoJS.enc.Utf8.parse(Key);
        const iv = CryptoJS.enc.Utf8.parse(IV);
        var options = { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7};
        var encrypted = CryptoJS.AES.encrypt(plainText, key, options);
        return encrypted.ciphertext.toString();
    } catch { return '';}
}

export const decrypt = (cipherText: string, Key: string, IV: string): string => {
    try{
        const key = CryptoJS.enc.Utf8.parse(Key);
        const iv = CryptoJS.enc.Utf8.parse(IV);
        var options = { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7};
        var decrypted = CryptoJS.AES.decrypt(CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(cipherText)}), key, options);
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch { return ''; }
}

