import { observer } from 'mobx-react-lite';
import React from 'react';
import { ICustomerCard } from '../../models';
import { useStore } from '../../stores/rootStore';
interface IProps{
  card: ICustomerCard;
}

const CardDetailRow: React.FC<IProps> = ({card}) => {
  const {commonStore:{userDefaultCard, setUserDefaultCard},paymentStore:{cardDeclined}} = useStore();
  const handleOnClick = (card: ICustomerCard) => {
      if(userDefaultCard?.uniqueRecId !== card.uniqueRecId)
        setUserDefaultCard(card);
  }
  return (
    <div className={`card-grid-row ${(cardDeclined && card.uniqueRecId === userDefaultCard?.uniqueRecId) ? 'card-declined' : ''}`}>
        <div>
            <label className="input-form-radio">
                <input name="cardDefault" type="radio" value={card.uniqueRecId} onClick={() => handleOnClick(card)} defaultChecked={card.referenceCode === userDefaultCard?.referenceCode} />
                <span className="checkmark"></span>
            </label>
        </div>
        <div><span>{card.cardType}</span></div>
        <div><span>{card.cardNumber}</span>
        {(cardDeclined && card.uniqueRecId === userDefaultCard?.uniqueRecId) && <><br /><span className='card-declined-note'>Card Declined</span></>}
        </div>
        <div><span>{card.cardExpiry}</span></div>
    </div>
  )
}
export default observer(CardDetailRow);
