import { observer } from 'mobx-react-lite'
import { useStore } from '../stores/rootStore'

const BillingDetails = () => {
    const {commonStore:{customer}} = useStore();
    if(!customer){
        return(
                <>
                <div className="section">
                    <h2 className="section-heading">Billing Details</h2>
                    <div className='section-row'>
                        <label>First Name</label>
                        <label>...Fetching...</label>
                    </div>
                    <div className='section-row'>
                        <label>Surname</label>
                        <label>...Fetching...</label>
                    </div>
                    <div className='section-row'>
                        <label>Address</label>
                        <label>...Fetching...</label>
                    </div>
                    <div className='section-row'>
                        <label>Postcode</label>
                        <label>...Fetching...</label>
                    </div>
                </div>
                </>
            )
    }
    return (
    <>
    <div className="section">
        <h2 className="section-heading">Billing Details</h2>
        <div className='section-row'>
            <label>First Name</label>
            <label>{customer.firstName}</label>
        </div>
        <div className='section-row'>
            <label>Surname</label>
            <label>{customer.surname}</label>
        </div>
        <div className='section-row'>
            <label>Address</label>
            <label>{customer.address1}</label>
        </div>
        <div className='section-row'>
            <label>Postcode</label>
            <label>{customer.postCode}</label>
        </div>
    </div>
    </>
  )
}

export default observer(BillingDetails);