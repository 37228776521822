import { useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import {PaymentBox} from '../components'
import { useStore } from '../stores/rootStore';

const DeclinedPage = () => {
  const {commonStore:{ apiCalled, apiReturned, apiError, setApiCalled, setLastError, userDefaultCard, defaultCard},paymentStore:{ setCardDeclined, createNewPaymentIntent, setPaymentSuccess, confirmationRequired, paymentResponseObject, updatePaymentSuccess }}= useStore()
  const stripe_api = useStripe();

  useEffect(()=>{
    setCardDeclined(true);
  },[])
 useEffect(()=>{
    if(apiReturned && !apiCalled)
      if(confirmationRequired && paymentResponseObject && stripe_api){
        stripe_api.confirmCardPayment(paymentResponseObject.clientSecret!,{payment_method: paymentResponseObject.paymentMethodId})
              .then((result) => {
                setApiCalled(false);
                if(result.error){
                  if(result.error.message)
                    setLastError(result.error.message)
                  else
                    setLastError("Payment FAILED!");
                } else {
                  if(result.paymentIntent.status === 'succeeded'){
                    updatePaymentSuccess();
                    setPaymentSuccess();
                  }
                  else  if(result.paymentIntent.status === 'requires_payment_method') {
                    setLastError("Payment FAILED!")
                  }
                }
              })
      }
  },[apiReturned,apiCalled,confirmationRequired])
  
  const handleSubmit = () => {
    if(userDefaultCard){
      if(defaultCard){
        if(userDefaultCard.uniqueRecId !== defaultCard.uniqueRecId){
              confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className='dialog-box'>
                        <div className="dialog-title">
                          <h2>Confirm Cancel Order</h2>
                        </div>
                        <div className="dialog-body">
                            <p>{`Do you want to set the ${userDefaultCard.cardType} ${userDefaultCard.cardNumber}, as the default card for future payments?`}</p>
                        </div>
                        <div className="dialog-footer">
                        <button className="btn btn-cancel" onClick={onClose}>No</button>
                        <button className="btn btn-default" onClick={() => {
                              onClose();        
                          }}>Yes</button>
                        </div>
                      </div>
                    );
                  }
              });
        }
      }
      createNewPaymentIntent(userDefaultCard.referenceCode);
    }
  }

  return (
      <PaymentBox handleSubmit={handleSubmit} saveDisabled={(apiCalled && !apiReturned) || apiError} isConfirm={false} />
  )
}

export default observer(DeclinedPage)