
interface Props{
    message: string;
}
export default function LoadingComponent({message}:Props){
return (
    <div className="loader-wrapper">
      <div className="loader"></div>
      <div>
          <p className="loader-message">{message}</p>
      </div>
    </div>
  );
};