import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../stores/rootStore';
interface IProps{
  handleSubmit: () => void;
  saveDisabled: boolean;
}
const Footer : React.FC<IProps> = ({handleSubmit,saveDisabled}) => {
  const { paymentStore:{paymentSuccess}, commonStore:{apiCalled, apiReturned, apiError, lastError} } = useStore();
  
  if(apiReturned && !apiCalled && paymentSuccess)
    return <div className='section-footer'>
      
    </div> 
  
  return (
    <div className='section-footer'>
        {apiReturned && !apiCalled && apiError && (
          <div>
            <span className='input-error'>{lastError}</span>
          </div>
        )}
        <button className='btn btn-default' onClick={handleSubmit} disabled={saveDisabled} >Pay Now</button>
    </div>
  )
}

export default observer(Footer);