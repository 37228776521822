import { observer } from 'mobx-react-lite'
import { useStore } from '../stores/rootStore';
import { CardDetail, CardDetailsGrid } from './card-details'
interface IProps{
  isConfirm: boolean;
}
const CardDetails : React.FC<IProps> = ({isConfirm}) => {
const {paymentStore:{paymentSuccess, duplicatePayment}, commonStore:{paymentObject, apiCalled, apiReturned, apiError, lastError}} = useStore()
if(paymentSuccess)
  return (
          <div className="section">
              <h2 className="section-heading">{duplicatePayment ? 'Duplicate Payment Attempt' : 'Payment Success'}</h2>
              {duplicatePayment ? (
                <>
                  <p>{`You have already made this payment of ${paymentObject!.currency.toUpperCase()} ${(paymentObject!.amount/100).toFixed(2)}`}</p>
                </>
              ) : (
                <>
                  <p>Thank You,</p>
                  <p>{`Your payment of ${paymentObject!.currency.toUpperCase()} ${(paymentObject!.amount/100).toFixed(2)} has been successfully processed`}</p>
                </>
              )}
          
          </div>
  )
if(apiReturned && !apiCalled && apiError)
    return(
          <div className="section">
              <h2 className="section-heading">Payment FAILED</h2>
              <p>{lastError}</p>
          </div>
    )
  return (
    <>
        <div className="section">
            <h2 className="section-heading">Card Details</h2>
            {isConfirm ? 
              <CardDetail />
             : 
               <CardDetailsGrid />
            }
        </div>

    </>
  )
}

export default observer(CardDetails);