import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { MessageBox } from './components';
import {Header} from './components/layout/';
import LoadingComponent from './components/LoadingComponent';
import './css/styles.css'
import { ConfirmPage, DeclinedPage, PaymentStatusPage, PayNowPage } from './pages';
import { useStore } from './stores/rootStore';
import { decrypt } from './utils/cipher';
const  App = () => {
  const [uid, setUID] = useState<string | null>();
  const [key, setKey] = useState<string | null>();
  const [searchParams, setSearchParams] = useSearchParams();
  const {commonStore:{appLoading, setAppLoaded, paymentObject, setPaymentObject, shop, loadShop, customer, loadCustomer, loadCustomerCards,invalidLink, setInvalidLink}} = useStore();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null); 
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions | undefined>(undefined);
  useEffect(()=>{
    if(searchParams.has('uid'))
      setUID(searchParams.get('uid'));
     else {
      setInvalidLink();
      setAppLoaded();
    }
    if(searchParams.has('key')){
      let paramKey = searchParams.get('key');
      if(paramKey)
        setKey(decodeURI(paramKey));
    }
    else {
      setInvalidLink();
      setAppLoaded();
    }
  },[searchParams])
  
  useEffect(()=>{
    if(uid && !shop){
      loadShop(uid);
    }
    if(shop){
      let colourScheme = JSON.parse(shop.colorScheme);
      Object.keys(colourScheme).forEach((key) =>{
      if (key.endsWith('image'))
        document.documentElement.style.setProperty(`--${key}`, `url(${colourScheme[key]})`)  
      else
        document.documentElement.style.setProperty(`--${key}`, colourScheme[key])  
      });
    }
  },[uid, shop])

  useEffect(() =>{
    if(key){
      if(!paymentObject){
        let cipher_key = process.env.REACT_APP_CIPHER_KEY ?? 'axcessitmydryclearnerapplication';
        let cipher_iv = process.env.REACT_APP_CIPHER_IV ?? 'mydryclearnerapp';
        let pobj = decrypt(key, cipher_key, cipher_iv);
        if(pobj.length > 0){
          setPaymentObject(JSON.parse(pobj));
        } else {
          setInvalidLink();
          setAppLoaded();
        }
      }
    }
  },[key,paymentObject,setPaymentObject])

  useEffect(() => {
    if(paymentObject && shop){
      if(customer){
        loadCustomerCards();
        if(stripePromise)        
          setAppLoaded();
      }
      else{
        loadCustomer(paymentObject.customerAccountCode);
      }
    }
  },[paymentObject,shop,customer,stripePromise]);
  
  useEffect(() => {
    if(paymentObject)
      if(paymentObject.clientSecret){
        setStripeOptions({clientSecret: paymentObject.clientSecret, appearance: {}}); 
    }
  },[paymentObject])

  useEffect(() => {
    if(shop)
     if(!stripePromise)
        setStripePromise(loadStripe(shop.cardKey));
  },[shop, stripePromise])
  if(invalidLink)
  return (
  <div className="wrapper">
      <div className="container">
        <Header />
          <MessageBox title='Invalid Link' message='You have clicked on an invalid link' /> 
      </div>
  </div>);
  return (
    <div className="wrapper">
      <div className="container">
        <Header />
        {appLoading ? ( <LoadingComponent message='Loading app...' /> ) : (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <Routes>
              <Route path='/paynow' element={<PayNowPage />} />
              <Route path='/confirm' element={<ConfirmPage />} />
              <Route path='/declined' element={<DeclinedPage />} />
              <Route path='/status' element={<PaymentStatusPage />} />
            </Routes>
          </Elements>
          )}
      </div>
    </div>
  );
}

export default observer(App);
