import axios, { AxiosResponse } from "axios";
import { IApiResponseEx, ICustomer, ICustomerCard, INewCard, INewStripeCard, IPaymentObject, IShop, IStripeIntent } from "../models";
import { IPaymentSuccessObject } from "../models/paymentobject";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) =>  axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody)
};

const Shop = {
  get: (uid: string): Promise<IApiResponseEx<IShop>> => request.get(`/shop/${uid}`),
};

const Customer = {
  info: (uid: string, appKey: string): Promise<IApiResponseEx<ICustomer>> => request.get(`/customer/${uid}?key=${appKey}`),
};

const CustomerCards = {
  all: (uid: string, appKey: string): Promise<IApiResponseEx<ICustomerCard[]>> => request.get(`/customercards/${uid}?key=${appKey}`),
  new: (card: INewCard) : Promise<IApiResponseEx<ICustomerCard>> => request.post('/customercards/new', card),
  default: (card: ICustomerCard): Promise<IApiResponseEx<boolean>> => request.post('/customercards/default', card),
  newStripeCard: (card: INewStripeCard): Promise<IApiResponseEx<ICustomerCard>> => request.post('/customercards/new/stripe', card) 
};
const StripePayment = {
  newIntent: (uid: string, key: string): Promise<IApiResponseEx<IStripeIntent>> => request.get(`/payment/stripe/intent/${uid}?key=${key}`),
  cancelIntent:  (uid: string, key: string): Promise<IApiResponseEx<boolean>> => request.del(`/payment/stripe/intent/cancel/${uid}?key=${key}`),
  newPayment: (pay: IPaymentSuccessObject): Promise<IApiResponseEx<IPaymentObject>> => request.post('/payment/stripe/intent/new', pay),
  paymentSuccess: (payment: IPaymentSuccessObject) : Promise<IApiResponseEx<boolean>> => request.post('/payment/success',payment),
}


const agent = {
    Shop, Customer, CustomerCards, StripePayment
};

export default agent;
