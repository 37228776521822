import { useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import {PaymentBox} from '../components'
import { useStore } from '../stores/rootStore';

const ConfirmPage = () => {
  const {commonStore:{ apiCalled, apiReturned, apiError, setApiCalled, paymentObject, setLastError, invalidLink},paymentStore:{ setCardDeclined, setPaymentSuccess, setDuplicatePayment, updatePaymentSuccess }} = useStore();
  const stripe_api = useStripe();
    
  useEffect(()=>{
    setCardDeclined(false);
  },[])

  const handleSubmit = () => {
    if(stripe_api && paymentObject){
      setApiCalled();
      stripe_api.confirmCardPayment(paymentObject.clientSecret!,{payment_method: paymentObject.paymentMethodId})
              .then((result) => {
                setApiCalled(false);
                if(result.error){
                    if(result.error.payment_intent?.status === 'succeeded'){
                      setDuplicatePayment();
                      setPaymentSuccess();
                    }
                    else
                    if(result.error.message)
                      setLastError(result.error.message)
                    else
                      setLastError("Payment FAILED!");
                } else {
                  if(result.paymentIntent.status === 'succeeded'){
                    updatePaymentSuccess();
                    setPaymentSuccess();
                  }
                  else  if(result.paymentIntent.status === 'requires_payment_method') {
                    setLastError("Payment FAILED!")
                  }
                }
              })
    }
  }

  return (
    <>
      <PaymentBox handleSubmit={handleSubmit} saveDisabled={invalidLink || (apiCalled && !apiReturned) || apiError} isConfirm={true} />
    </>
  )
}

export default observer(ConfirmPage)