import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/rootStore';

const PaymentDetails = () => {
    const {commonStore:{paymentObject}} = useStore();

    if(!paymentObject){
        return (
            <>
                <div className="section">
                    <h2 className="section-heading">Payment Details</h2>
                    <div className='section-row'>
                        <label>Reference No.</label>
                        <label>...Fetching...</label>
                    </div>
                    <div className='section-row'>
                        <label>Amount</label>
                        <label>...Fetching...</label>
                    </div>
                </div>
            </>
        )
    }
  return (
    <>
    <div className="section">
        <h2 className="section-heading">Payment Details</h2>
        <div className='section-row'>
            <label>Reference No.</label>
            <label>{paymentObject.reference}</label>
        </div>
        <div className='section-row'>
            <label>Amount</label>
            <label>&pound;&nbsp;{(paymentObject.amount/100).toFixed(2)}</label>
        </div>
    </div>

    </>
  )}

export default observer(PaymentDetails);