import { observer } from 'mobx-react-lite';
import { CardDetailRow } from '.';
import { useStore } from '../../stores/rootStore';

const CardDetailsGrid = () => {
  const {commonStore:{ customerCards, userDefaultCard, paymentObject, apiCalled, apiReturned},paymentStore:{setShowDialog}} = useStore();
  if(customerCards.length === 0)
    return (
      <>
          <div className="card-grid-header">
              <div><span>Card Type</span></div>
              <div><span>Card Number</span></div>
              <div><span>Expiry</span></div>
          </div>
          <div className="card-grid-row">Fetching card details...</div>
          <div className="card-grid-footer">
            <span></span>
          <button className="btn btn-default btn-small" disabled={true} onClick={ (event) => { event.preventDefault();}}>Add a Credit/Debit card</button>
          </div>

      </>
    )  
  return (
    <>
        <div className="card-grid-header">
            <div className='left-align'><span>Card Type</span></div>
            <div className='left-align'><span>Card Number</span></div>
            <div><span>Expiry</span></div>
        </div>
        { customerCards.map((card) => (
            <CardDetailRow key={card.uniqueRecId} card={card} />
       ))}
        <div className="card-grid-footer">
          {userDefaultCard && paymentObject && (
              <span>We will charge &pound;&nbsp;{(paymentObject.amount/100).toFixed(2)} for this payment using your {userDefaultCard.cardType} {userDefaultCard.cardNumber}</span>
          )}
          <button className="btn btn-default btn-small" disabled={apiCalled && !apiReturned} onClick={ (event) => { event.preventDefault(); setShowDialog();}}>Add a Credit/Debit card</button>
        </div>
    </>
  )
}
export default observer(CardDetailsGrid);
