import { useStripe } from "@stripe/react-stripe-js";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BillingDetails, CardDetails, LoadingComponent, PaymentDetails } from "../components";
import { useStore } from "../stores/rootStore";

const PaymentStatusPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [intentId, setIntentId] = useState<string|null>();
  const [clientSecret, setClientSecret] = useState<string|null>();
  const {commonStore:{invalidLink,setInvalidLink,setLastError,setApiCalled,apiCalled,apiReturned},paymentStore:{setPaymentSuccess,updatePaymentSuccess}} = useStore();
  const stripe_api = useStripe();
  useEffect(()=>{
    if(searchParams.has('setup_intent'))
      setIntentId(searchParams.get('setup_intent'))
    else
      setInvalidLink();
    if(searchParams.has('setup_intent_client_secret'))
      setClientSecret(searchParams.get('setup_intent_client_secret'))
    else 
      setInvalidLink();
  },[searchParams])

  useEffect(()=>{
    if(clientSecret && stripe_api){
      setApiCalled();
      stripe_api.retrieveSetupIntent(clientSecret)
        .then((response) => {
          if(response.error){
            setLastError(response.error.message ?? 'An unknown error occured while processing!');
          } else {
            switch(response.setupIntent.status){
              case "succeeded":
                updatePaymentSuccess();
                setPaymentSuccess();
                break;
              case "processing":
                setLastError('Your payment is being processed')
                break;
              case "requires_payment_method":
                  setLastError('Failed to process payment details. Please try another payment method')
                  break;
              default:
                 setLastError('An error occured while processing your card');
            }
          }
          setApiCalled(false);
        })
    }

  },[clientSecret, stripe_api])
  if(apiCalled && !apiReturned)
    return <LoadingComponent message="Fetching status..."/>
  return (
    <>
      <PaymentDetails />
      <BillingDetails />
      <CardDetails isConfirm={false} />
    </>
  )
}

export default observer(PaymentStatusPage);