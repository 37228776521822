import React from "react";

interface IProps {
  title: string;
  message: string;
}
const MessageBox: React.FC<IProps> = ({
  title, message
}) => {
  return (
      <div className="message-box">
        <div className="message-box-title">
          <h2 data-alter-title>{title}</h2>
        </div>
        <div className="message-box-body">
          <p>{message}</p>
        </div>
      </div>
  );
};

export default MessageBox;