import { observer } from 'mobx-react-lite';
import { BillingDetails, CardDetails, PaymentDetails } from '.'
import { useStore } from '../stores/rootStore';
import { Footer } from './layout'
import { StripeDialogWrapper } from './Stripe/StripeDialogWrapper';
interface IProps{
  handleSubmit: () => void;
  saveDisabled: boolean;
  isConfirm: boolean;
}
const PaymentBox : React.FC<IProps> = ({handleSubmit, saveDisabled, isConfirm}) => {
  const {paymentStore:{showDialog}} = useStore();
  return (
    <>
        <PaymentDetails />
        <BillingDetails />
        <CardDetails isConfirm={isConfirm} />
        <Footer handleSubmit={handleSubmit} saveDisabled={saveDisabled} />
        {showDialog && <StripeDialogWrapper />}
    </>
  )
}

export default observer(PaymentBox)