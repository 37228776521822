import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatus, ICustomer, ICustomerCard, INewStripeCard, IPaymentObject, IShop } from "../models";

export default class CommonStore{

    appLoading = true;
    apiKey = "";
    apiCalled = false;
    apiReturned = false;
    apiError = false;
    lastError = "";
    invalidLink = false;

    paymentObject: IPaymentObject|undefined;
    shop: IShop | undefined;
    customer: ICustomer | undefined;
    customerCards: ICustomerCard[] = [];
    defaultCard: ICustomerCard | undefined;
    userDefaultCard: ICustomerCard | undefined;
    
    constructor(){
        makeAutoObservable(this);
    }
    
    

    setAppLoaded = () => {
        this.appLoading = false;
    }
    
    setInvalidLink = () => {
        this.invalidLink = true;
    }

    setPaymentObject = (pObj: IPaymentObject | undefined) => {
        this.paymentObject = pObj;
    }
    
    resetLastError = () => {
        this.lastError = "";
    }
    
    setApiCalled = (called: boolean = true) => {
        this.apiCalled = called;
        runInAction(() => {
            this.apiReturned = !called;
            if(called)
                this.resetLastError();
        });
    }
    
    setLastError = (error: string) => {
        this.apiReturned = true;
        runInAction(() => {
            this.apiError = true;
            this.lastError = error;
        })
    }
    
    setUserDefaultCard = (card: ICustomerCard) => {
       this.userDefaultCard = card; 
    }

    loadShop = (uid: string) => {
        this.setApiCalled();
        agent.Shop.get(uid)
            .then((response) => {
                if(response.status === ApiResponseStatus.ResponseOK){
                    runInAction(() => {
                        this.apiKey = response.result.appKey;
                        this.shop = response.result;
                    });
                } else {
                    this.setLastError(response.error.description);
                }
            });
        this.setApiCalled(false);
    }

    loadCustomer = (uid: string) => {
        this.setApiCalled();
        agent.Customer.info(uid, this.apiKey)
            .then((response) => {
                if(response.status ===  ApiResponseStatus.ResponseOK){
                    runInAction(() => this.customer = response.result);
                } else {
                    this.setLastError(response.error.description);
                }
            })
        this.setApiCalled(false);
    }

    loadCustomerCards = () => {
        this.setApiCalled();
        if(this.customer)
        agent.CustomerCards.all(this.customer.accountCode,this.apiKey)
            .then((response) => {
                if(response.status === ApiResponseStatus.ResponseOK){
                    runInAction(() => {
                        this.customerCards = response.result.sort(c => c.referenceCode === this.paymentObject!.paymentMethodId ? -1 : 1);
                        this.defaultCard = response.result.find((c) => c.referenceCode === this.paymentObject!.paymentMethodId);
                        this.userDefaultCard = this.defaultCard;
                    })
                } else {
                    this.setLastError(response.error.description);
                }
            })
        this.setApiCalled(false);
    }

    saveStripeCard = (uid: string) => {
        let card: INewStripeCard = {
            accountCode: this.customer!.accountCode,
            appKey: this.apiKey,
            shopCode: this.shop!.shopCode,
            id: uid,
            instanceId: this.customer!.instanceId,
            tillNo: this.shop!.terminalId
        };
        this.setApiCalled();
        agent.CustomerCards.newStripeCard(card)
            .then((response) => {
                if(response.status === ApiResponseStatus.ResponseOK){
                    runInAction(() => {
                        let savedCard = response.result;
                        this.customerCards = [...this.customerCards, savedCard].sort(c => c.uniqueRecId === savedCard.uniqueRecId ? -1 : 1)
                        this.defaultCard = this.customerCards.find(c => c.uniqueRecId === savedCard.uniqueRecId)
                        this.userDefaultCard = this.defaultCard;
                    })
                } else {
                    this.setLastError(response.error.description);
                }
            })
        this.setApiCalled(false);
    }
}