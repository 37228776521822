import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores/rootStore'

const Header = () => {
    const {commonStore:{shop}} = useStore();
  return (
    <div className="logo">
        <img src={ shop?.logoPath ? `${shop?.appDocUrl}/Logo/${shop?.logoPath}` : "/images/logo-v.png"} alt={shop?.shopName || "My Drycleaner"} />
        <h1 className="logo-title">{shop?.showCompanyName && (shop?.shopName || "My Drycleaner Payments")}</h1>
    </div>
  )
}

export default  observer(Header);