import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/rootStore";
import { StripeDialog } from "./StripeDialog";


export const StripeDialogWrapper = observer(() => {
    
    const {commonStore:{shop},paymentStore:{stripeIntent,createStripeIntent}} = useStore();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null); 
    const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions | undefined>(undefined);

    useEffect(() => {
    if(!stripeIntent){
        createStripeIntent();
    } else if(stripeIntent && stripeIntent!.secret.length > 0){
        setStripeOptions({clientSecret: stripeIntent!.secret, appearance: {}}); 
    }
    },[stripeIntent])

    useEffect(() => {
        if(shop){
            if(!stripePromise)
                setStripePromise(loadStripe(shop!.cardKey));
        }
    },[shop, stripePromise])

    if(!stripeOptions)
        return <p>Loading</p>;
     return (
    <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeDialog />
    </Elements>

  )
});