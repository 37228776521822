export interface IApiResponse {
    status: ApiResponseStatus;
    error: IApiResponseError;
}

export interface IApiResponseEx<T> extends IApiResponse {
    result: T;
}

export interface IApiResponseError {
    code: ApiResponseErrorCode;
    description: string;
}

export enum ApiResponseStatus {
    ResponseError,
    ResponseOK
}

export enum ApiResponseErrorCode {
    ResponseErrorNotFound = 101,
    ResponseErrorFailed = 102,
    ResponseErrorBadRequest = 103,
    ResponseInvalidCustomer = 104,
    ResponseErrorInvalidKey = 201,
    ResponseErrorExpiredKey = 202,
    ResponseErrorAuthenticationFailed = 300,
    ResponseErrorUnAuthorized = 301,
    ResponseErrorProviderNotConfigured = 401,
    ResponseErrorSavingCard = 402,
    ResponseErrorCardDeclined = 403,
    ResponseErrorCardBlocked = 404,
    ResponseErrorCardExpired = 405,
    ResponseErrorCardExtrasRequired = 406,
    ResponseErrorPaymentFailed = 407,
    ResponseErrorServerError = 500
}