import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseErrorCode, ApiResponseStatus, IPaymentObject, IStripeIntent } from "../models";
import { IPaymentSuccessObject } from "../models/paymentobject";
import { store } from "./rootStore";

export default class PaymentStore{

    cardApiCalled = false;
    cardApiReturned = false;
    cardApiError = false;
    cardLastError = "";

    cardDeclined = false;
    showDialog = false;
    savingCard = false;
    stripeIntent: IStripeIntent | undefined;
    paymentSuccess = false;
    duplicatePayment = false;
    confirmationRequired = false;
    paymentResponseObject: IPaymentObject | undefined;
    
    constructor(){
        makeAutoObservable(this);
    }
    resetCardLastError = () => {
        this.cardLastError = "";
    }
    
    setCardApiCalled = (called: boolean = true) => {
        this.cardApiCalled = called;
        runInAction(() => {
            this.cardApiReturned = !called;
            if(called)
                this.resetCardLastError();
        });
    }
    
    setCardLastError = (error: string) => {
        this.cardApiReturned = true;
        runInAction(() => {
            this.cardApiError = true;
            this.cardLastError = error;
        })
    }



    setCardDeclined = (declined: boolean) => {
        this.cardDeclined = declined;
    }

    setShowDialog = (show: boolean = true) => {
        this.showDialog = show;
    }

    setSavingCard = (saving: boolean = true) => {
        this.savingCard = saving;
    }

    setPaymentSuccess = () => {
            this.paymentSuccess = true;
    }

    setDuplicatePayment = () => {
        this.duplicatePayment = true;
    }
    createStripeIntent = () => {
        store.commonStore.setApiCalled();
        agent.StripePayment.newIntent(store.commonStore.customer!.accountCode,store.commonStore.shop!.appKey)
            .then((response) => {
                if(response.status === ApiResponseStatus.ResponseOK){
                    runInAction(() => this.stripeIntent =  response.result);
                } else {
                    store.commonStore.setLastError(response.error.description);
                }
            })
        store.commonStore.setApiCalled(false);
    }
    cancelStripeIntent = () => {
        store.commonStore.setApiCalled();
        agent.StripePayment.cancelIntent(this.stripeIntent!.id,store.commonStore.apiKey)
            .then((response) => {
                if(response.status ===  ApiResponseStatus.ResponseOK){
                    runInAction(()=> this.stripeIntent = undefined);
                } else {
                    store.commonStore.setLastError(response.error.description);
                }
            })
        store.commonStore.setApiCalled(false);
    }
    updatePaymentSuccess = () => {
        const {paymentObject,customer,apiKey,shop, setApiCalled, setLastError} = store.commonStore;
        setApiCalled();
        if(paymentObject && customer && shop){
            const paySuccess:IPaymentSuccessObject = { ...paymentObject,
                appKey: apiKey,
                instanceId: customer.instanceId,
                tillNo: shop.terminalId,
            };
            agent.StripePayment.paymentSuccess(paySuccess)
                .then((response)=>{
                    if(response.status === ApiResponseStatus.ResponseError)
                        setLastError(response.error.description);                    
                });
            setApiCalled(false);
        }
    }
    createNewPaymentIntent = (paymentMethodId: string) => {
        store.commonStore.setApiCalled();
        const {paymentObject,shop,customer, apiKey} = store.commonStore;
        if(paymentObject && shop && customer ){
            const stripePay: IPaymentSuccessObject = { ...paymentObject,
                paymentMethodId: paymentMethodId,
                appKey: apiKey,
                tillNo: shop.terminalId,
                instanceId: customer.instanceId
        };
        agent.StripePayment.newPayment(stripePay)
            .then((response) => {
                if(response.status === ApiResponseStatus.ResponseOK){
                    this.updatePaymentSuccess();
                    this.setPaymentSuccess();
                } else {
                    if(response.error.code === ApiResponseErrorCode.ResponseErrorCardExtrasRequired){
                        runInAction(() => {
                            this.confirmationRequired = true;
                            this.paymentResponseObject = response.result;
                        })
                    }
                    else 
                        store.commonStore.setLastError(response.error.description);
                }
            })
        }
        store.commonStore.setApiCalled(false);
    }
}