import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react'
import { ICustomerCard } from '../../models';
import { useStore } from '../../stores/rootStore';
import MessageBox from '../MessageBox';

const CardDetail = () => {
    const {commonStore:{ customerCards, paymentObject }} = useStore();
    const [card, setCard] = useState<ICustomerCard | undefined>();
    useEffect(() => {
        if(customerCards.length > 0 && paymentObject){
            setCard(customerCards.find(c => c.referenceCode === paymentObject.paymentMethodId));
        }
    
    }, [customerCards, paymentObject])

    if(card === undefined){
        <MessageBox title='Payment Error' message='You have clicked on an invalid link' />
    }
 return (
    <>
        <div className="card-grid-header">
            <div className='left-align'><span>Card Type</span></div>
            <div className='left-align'><span>Card Number</span></div>
            <div><span>Expiry</span></div>
        </div>
        {card && 
            <div className='card-grid-row'>
                <div>
                    <label className="input-form-radio">
                        <input name="cardDefault" type="radio" value={card.uniqueRecId} defaultChecked={true} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div><span>{card.cardType}</span></div>
                <div><span>{card.cardNumber}</span>
                </div>
                <div><span>{card.cardExpiry}</span></div>
            </div>
        }
        <div className="card-grid-footer">
          {(card && paymentObject) && (
              <span>We will charge &pound;&nbsp;{(paymentObject.amount/100).toFixed(2)} for this payment using your {card.cardType} {card.cardNumber}</span>
          )}
        </div>
    </>
  )
}

export default observer(CardDetail)