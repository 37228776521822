import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntentResult } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/rootStore';
import Dialog from '../Dialog';

export const StripeDialog = observer(() => {
    const {commonStore:{saveStripeCard},paymentStore:{cardApiCalled,cardApiError,cardApiReturned, cardLastError, setCardApiCalled,setCardLastError, cancelStripeIntent, showDialog,setShowDialog}} = useStore()
    const stripe_api = useStripe();
    const stripe_elements = useElements();
    const handleCancel = async () => {
        cancelStripeIntent();
        setShowDialog(false);
    }
    const handleSubmit = async () => {
        if(stripe_api && stripe_elements){
            let returnURL = `${window.location.protocol}//${window.location.host}/status` 
            setCardApiCalled();
            stripe_api.confirmSetup({elements: stripe_elements, confirmParams : { return_url:  returnURL}, redirect: 'if_required'})
                .then( async (response: SetupIntentResult) => {
                    if(response.error){
                        setCardLastError(response.error.message!);
                    } else if(response.setupIntent){
                        switch(response.setupIntent.status){
                            case 'succeeded':
                                if(response.setupIntent.payment_method){
                                    if(typeof response.setupIntent.payment_method === 'string'){
                                        saveStripeCard(response.setupIntent.payment_method)
                                    }}
                                break;
                            case 'processing':
                                setCardLastError('Your card is being processed')
                                break;
                            case "requires_payment_method":
                                setCardLastError('Failed to process payment details. Please try another payment method')
                                break;
                            default:
                                setCardLastError("An error occured while saving your card!");
                        }
                    }
                })
            setCardApiCalled(false);
        }
    }

    return (
        <Dialog title="Add new Credit/Debit card" showDialog={showDialog} hideDialog={handleCancel} saveData={handleSubmit} saveDisabled={(cardApiCalled && !cardApiReturned) || cardApiError}>
            <form>
                <PaymentElement />
            </form>
            {!cardApiCalled && cardApiError && cardApiReturned && (<span className="input-error-big">{cardLastError}</span>)}
        </Dialog>
  )
});
