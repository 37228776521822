import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import PaymentStore from "./paymentStore";

interface Store{
    commonStore: CommonStore;
    paymentStore: PaymentStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    paymentStore: new PaymentStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}